import { Component, ElementRef, Input, HostListener, Renderer2 } from '@angular/core';

@Component({
    selector: 'sPopup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.less'],
})
export class PopupComponent {
    @Input() hideCloseButton: boolean = false;

    private scrollTop = 0;
    private scrollLeft = 0;
    private scrollDisabled = false;

    public constructor(public erf: ElementRef, private renderer: Renderer2) {}

    ngDoCheck() {
        if (this.erf.nativeElement.classList.contains('hide')) {
            this.enableScroll();
        } else {
            this.disableScroll();
        }
    }

    public closePopup(e?: Event): void {
        if (e && e.target) {
            //this.erf.nativeElement
            let elem = e.target as HTMLElement;
            if (!elem.className.match(/popup-wrapper-/)) {
                return;
            }
        }

        this.erf.nativeElement.classList.add('hide');
        document.body.style.height = '';
        document.body.style.overflow = '';
    }

    // Function to disable scrolling
    disableScroll() {
        if (!this.scrollDisabled) {
            this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            this.scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

            this.renderer.setStyle(document.body, 'overflow', 'hidden');
            this.renderer.setStyle(document.body, 'position', 'fixed');

            this.scrollDisabled = true;
        }
    }

    // Function to enable scrolling
    enableScroll() {
        if (this.scrollDisabled) {
            this.renderer.removeStyle(document.body, 'overflow');
            this.renderer.removeStyle(document.body, 'position');

            window.scrollTo(this.scrollLeft, this.scrollTop);

            this.scrollDisabled = false;
        }
    }

    // Listen for scroll events
    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event) {
        if (this.scrollDisabled) {
            event.preventDefault();
        }
    }
}
