import { Component, Input, forwardRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

let id: number = 0;

@Component({
    selector: 'sRadio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioComponent),
            multi: true,
        },
    ],
})
export class RadioComponent implements ControlValueAccessor {
    @Input('disabled') public disabled: boolean;
    @Input('name') public name: string;
    @Input('value') public value: string;
    @Input('sClass') public sClass = '';
    @Input('label') public label: string;
    @Input('checked') public checked: boolean = false;

    @ViewChild('radio', { static: true }) public radio: ElementRef;

    public id: number;

    public onChange: (val: any) => void;
    public onTouched: () => void;

    public constructor(private renderer: Renderer2) {
        id += 1;
        this.id = id;
    }

    /**
     * Registers the onChange function
     */
    public registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers the onTouch function
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: any): void {
        if (value === this.value) {
            this.checked = true;
        } else {
            // WORKAROUND: Angular doesn't uncheck radio buttons properly
            this.renderer.setProperty(this.radio.nativeElement, 'checked', false);
            this.checked = false;
        }
    }

    public onChanged(): void {
        this.onChange(this.value);
        this.onTouched();
    }
}
