import { Component, Input, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'sSelect',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements ControlValueAccessor {
    /** The current value of the selector */
    public set value(val: string) {
        if ((!this.options || this.options.length > 5) && this.select != undefined) {
            const eleValue: string = val == null ? '' : String(val);
            const $select: HTMLSelectElement = this.select.nativeElement;

            $select.value = null; // Make sure the shown value changes
            $select.value = eleValue;
        }
        this._value = val !== this._value ? val : undefined;

        this.onChange(this._value);
    }
    public get value(): string {
        return this._value;
    }
    @Input('value') private _value: string = '';

    /** The visible name of the selector */
    @Input('label') public label: string = '';

    /** Whether an option needs to be selected */
    @Input('required') public required: any = null;

    /** The disabled state, `null`, `undefined` or `false` means it's not disabled */
    @Input('disabled') public disabled: any = null;

    /** The classes of the selector */
    @Input('sClass') public sClass: string = '';

    /** The options the user can select */
    @Input('options') public options: Array<{ id: string; value: string }> = null;

    // Hooks from Angular
    public onChange: (val: any) => void = () => {};
    public onTouched: () => void = () => {};

    /** The `<select>` element */
    @ViewChild('select', { static: false }) private select: ElementRef;

    public constructor() {}

    public writeValue(value: string): void {
        this.value = value !== this.value ? value : undefined;
    }
    public registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * When the user chooses an option in the <select> element
     *
     * @param     event    The    (select) event
     * @author    Mike van Os <mike@safira.nl>
     */
    public choose(event: Event): void {
        this.value = (event.target as HTMLInputElement).value;
    }

    /**
     * When the user clicks an option in the short select box
     *
     * @param     value    The    id of the option the user clicked
     * @author    Mike van Os <mike@safira.nl>
     */
    public switch(value: string): void {
        if (this.isDisabled()) return;

        this.value = value;
        this.onChange(this.value);
        this.onTouched();
    }

    /**
     * Has this selector been disabled?
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public isDisabled(): boolean {
        return this.disabled !== false && this.disabled != null;
    }

    /**
     * Is this selector required?
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public isRequired(): boolean {
        return this.required !== false && this.required != null;
    }
}
