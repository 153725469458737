import { Component, OnInit } from '@angular/core';
import { CountryService } from '../../../../../core/services/country.service';
import { UserAddressModel } from '../../../../../core/models/user/user-address.model';
import { UserService } from '../../../../../core/services/user/user.service';

@Component({
    selector: 'address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.less'],
})
export class AddressComponent implements OnInit {
    constructor(public userService: UserService, public countryService: CountryService) {}

    async ngOnInit() {
        if (this.countryService.countries == null) {
            await this.countryService.getAll();
        }
    }

    addAddress() {
        this.userService.object.addresses.push(new UserAddressModel());
    }

    toggleDeleteAddress(id: number) {
        this.userService.object.addresses[id].deleted = !this.userService.object.addresses[id].deleted;
    }

    updateAddressType(address: UserAddressModel, isDelivery: boolean) {
        let key = isDelivery ? 'isDelivery' : 'isBilling';
        for (let addr of this.userService.object.addresses) {
            addr[key] = 0;
        }
        address[key] = 1;
    }
}
