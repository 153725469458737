import { AuthenticateService } from 'src/app/core/services/auth/authenticate.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../../../core/services/user/user.service';
import { AjaxService } from 'src/app/core/services/ajax.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
@Component({
    selector: 'general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.less'],
})
export class GeneralComponent implements OnInit {
    showPasswordBtn: boolean = false;
    inviteLink: string;

    constructor(
        public userService: UserService,
        public authenticateService: AuthenticateService,
        private router: Router,
        private ajax: AjaxService,
        private toaster: ToasterService
    ) {}

    ngOnInit(): void {
        if (this.router.url.slice(this.router.url.lastIndexOf('/') + 1) !== 'new') {
            this.showPasswordBtn = true;
        }
    }

    public async getInviteLink() {
        const hash = await this.ajax.post('user/passwordForgot/getInviteHash/', {
            email: this.userService.object.email,
        });
        const domain = new URL(window.location.href).host;
        this.inviteLink = 'https://' + domain + '/welcome/' + hash;
    }

    public copyLink() {
        navigator.clipboard.writeText(this.inviteLink);
        this.toaster.success('Link gekopieerd!');
    }
}
