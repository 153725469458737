import { Breakpoint, ResponsiveService } from 'src/app/core/services/responsive.service';
import { Component, Input, Type } from '@angular/core';

@Component({
    selector: 'sFooter',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.less'],
})
export class FooterComponent {
    @Input() type: string = 'expanded';
    readonly breakpoint = Breakpoint.LG;
    constructor(public responsive: ResponsiveService) {}

    public onClick(data) {
        if (!this.responsive.isSize(this.breakpoint)) {
            if (!data.target.parentElement.querySelector('.col-content').classList.contains('open')) {
                data.target.parentElement.querySelector('.col-content').classList.add('open');
                data.target.classList.add('clicked');
            } else {
                data.target.parentElement.querySelector('.col-content').classList.remove('open');
                data.target.classList.remove('clicked');
            }
        } else {
            return;
        }
    }
}
