import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'dashboard-box',
    templateUrl: './dashboard-box.component.html',
    styleUrls: ['./dashboard-box.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DashboardBoxComponent),
            multi: true,
        },
    ],
})
export class DashboardBoxComponent {
    @Input('name') public name: string;

    @Input('description') public description: string;

    @Input('icon') public icon: string = 'text';

    @Input('link') public link: string = '';

    public constructor() {}
}
