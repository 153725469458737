import { Injectable, Injector } from '@angular/core';
import { ToasterService } from '../toaster.service';
import { AjaxService } from '../ajax.service';
import { UserService } from '../user/user.service';
import { StorageService } from '../storage.service';
import { Router } from '@angular/router';
import { UtilService } from '../util.service';
import { ValidationService } from '../validation.service';
import { ConfigService } from '../config.service';
import { Auth, AuthProvider, GoogleAuthProvider, signInWithPopup } from '@angular/fire/auth';
import { FirebaseError } from 'firebase/app';
import { AccountService } from '../user/account.service';
import { color, item } from '../../models/item.model';
import { environment } from '../../../../environments/environment';
import { LocationStrategy } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class AuthenticateService {
    infoItem: item;

    /* Two-factor authentication */
    public sessionToken: string;
    public skipBarcode: boolean;
    public otpUrl: string;
    public errorCode: boolean = false;
    public googleCode: string;
    private afauth: Auth;
    private readonly API_KEY = 'aTr5hGxktNVTacQGL6zCuHJB';

    constructor(
        private ajax: AjaxService,
        private account: AccountService,
        private toaster: ToasterService,
        private userService: UserService,
        private storage: StorageService,
        private router: Router,
        private injector: Injector,
        private util: UtilService,
        private validationService: ValidationService,
        private config: ConfigService,
        private locationStrategy: LocationStrategy
    ) {
        if ('apiKey' in environment.firebase) {
            this.afauth = injector.get(Auth);
        }
    }

    public setInfoItem(text: string, type: color = 'error') {
        this.infoItem = {
            type: 'text',
            icon: 'exclamation-circle',
            text: text,
            color: type,
            background: (type + '-light') as color,
            padding: '20px',
            margin: '0 0 20px 0',
            width: '100%',
        };
        setTimeout(() => {
            this.infoItem = undefined;
        }, 5000);
    }

    public async checkIsSafira(): Promise<void> {
        const res: string = (await this.ajax.get('user/getIp')) as string;
        if (
            res === this.config.safiraIp ||
            res === this.config.safiraIpv4 ||
            res.includes(this.config.hestiaInternal)
        ) {
            this.config.isSafira = true;
        }
    }

    /**
     * Login user
     */
    public async login(
        email: string,
        password: string,
        rememberMe: boolean,
        redirect: string,
        showAuthCode: boolean = false,
        loginCode: string = ''
    ): Promise<unknown> {
        const appname =
            window.location.hostname === 'localhost'
                ? null
                : this.trimLastSlashFromUrl(this.locationStrategy.getBaseHref());
        this.infoItem = undefined;

        if (this.util.empty(email)) {
            this.setInfoItem('itemLogin.empty_email');
            return false;
        }
        if (this.util.empty(password)) {
            this.setInfoItem('itemLogin.empty_password');
            return false;
        }

        const loginResult = await this.ajax
            .post(
                'user/login',
                {
                    email,
                    password,
                    rememberMe,
                    showAuthCode,
                    loginCode,
                    appname,
                },
                '.login-wrapper-outer'
            )
            .catch((message) => {
                this.setInfoItem(message);
                return message;
            });

        // Success
        // If login is valid && 2fa is requested
        if (loginResult['verification']) {
            if (loginResult['verification']['errors'] == 1) {
                loginResult['error']['code'] = 4;
                this.toaster.error(loginResult['verification']['message']);
                this.validationService.checkForErrors(['loginCode'], false);
            } else {
                localStorage.setItem('authToken', loginResult['token']);
                localStorage.setItem('apiKey', this.API_KEY);

                loginResult['error']['code'] = 3;
                this.toaster.success(loginResult['verification']['message']);
            }
        }
        if (loginResult['error']['code'] === 0) {
            // Set login
            this.storage.setRelevantStorage(rememberMe);
            this.storage.setStorage('apiKey', this.API_KEY);
            if (loginResult['2fa_token']) {
                this.addTo2FAStorage(loginResult['2fa_token']);
            }
            this.sessionToken = '';

            if (loginResult['use_two_factor_authentication'] && loginResult['authentication_method'] == '1') {
                this.sessionToken = loginResult['token'];
                this.skipBarcode = loginResult['skipBarcode'];

                if (!this.skipBarcode) {
                    this.otpUrl = loginResult['otpUrl'];
                }
            } else {
                this.storage.setStorage('authToken', loginResult['token']);
                await this.router.navigateByUrl('/configurator');
            }
        } else if (loginResult['error']['code'] === 401) {
            // TODO Fix this if check to not use a message #cry
            if (loginResult['error']['message'] === 'Maximum login attempts reached') {
                this.setInfoItem('itemLogin.account_blocked');
            } else {
                this.setInfoItem('itemLogin.wrong_login');
            }
        }
        return loginResult;
    }

    public addTo2FAStorage(token: string) {
        let tokens: string[] = JSON.parse(localStorage.getItem('2fa_app')) ?? [];

        // prettier-ignore
        if (tokens && !tokens.find(storageToken => storageToken === token)) {
            tokens.push(token);
            localStorage.setItem('2fa_app', JSON.stringify(tokens));
        }
    }

    public deleteFrom2FAStorage(token: string) {
        let tokens: string[] = JSON.parse(localStorage.getItem('2fa_app'));
        if (!tokens) return;
        // prettier-ignore
        let index = tokens.findIndex(storageToken => storageToken === token);
        if (index < 0) return;
        tokens.splice(index, 1);
        localStorage.setItem('2fa_app', JSON.stringify(tokens));
    }

    /**
     * logout
     *
     * Log's out a user
     */
    public async logout() {
        await this.ajax.get('user/logout');
        // Delete apikey and authtoken
        this.storage.deleteStorage('apiKey');
        this.storage.deleteStorage('authToken');
        this.storage.deleteStorage('adminToken');

        //Clear checkbox value
        this.config.rememberMe = false;
        await this.router.navigate(['/login']);

        this.account.object = undefined;
        this.account.accountUpdated.next();
    }

    /**
     * Log in as the user with the userId
     */
    public loginAs(userId: number, currentPassword: string): Promise<string> {
        return this.ajax
            .post('user/loginAs', { userId, currentPassword })
            .then(async ({ error, token }: { error: any; token: string }) => {
                if (error['code'] === 404) {
                    this.toaster.error(
                        'Kan niet als deze gebruiker inloggen omdat hij aan geen enkele groep is toegewezen.'
                    );
                    throw new Error(error['message']);
                } else if (error['code'] === 401) {
                    this.toaster.error('Dat wachtwoord is onjuist.');
                    throw new Error(error['message']);
                } else if (error['code'] !== 0) {
                    this.toaster.error(error['message']);
                    throw new Error(error['message']);
                }

                this.storage.setStorage('adminToken', this.storage.getStorage('authToken'));
                this.storage.setStorage('authToken', token);

                await this.account.get();
                this.account.accountUpdated.next();

                await this.router.navigateByUrl(this.account.object.adminAccount ? '/admin' : '/');

                return token;
            });
    }

    /**
     * Go back to the admin account
     */
    public async goBackToAdmin(): Promise<void> {
        await this.ajax.get('user/logout');

        const adminToken = this.storage.getStorage('adminToken');

        this.storage.deleteStorage('adminToken');
        this.storage.setStorage('authToken', adminToken);

        await this.account.get();
        this.account.accountUpdated.next();

        await this.router.navigateByUrl(this.account.object.adminAccount ? '/admin' : '/');
    }

    /**
     * Login with Google via Firebase
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public async loginWithGoogle(url: string = '/dashboard'): Promise<boolean> {
        const provider: GoogleAuthProvider = new GoogleAuthProvider();
        provider.addScope('email');

        await this.loginWithFirebase(provider).catch((reason: FirebaseError) => {
            if (
                reason.code === 'auth/cancelled-popup-request' ||
                reason.code === 'auth/popup-closed-by-user'
            ) {
                this.setInfoItem('itemLogin.canceled_login');
            } else if (reason.code === 'auth/popup-blocked') {
                this.setInfoItem('itemLogin.popup_blocker');
            } else {
                this.setInfoItem('itemLogin.unknown_error');
            }
            return false;
        });

        if (this.sessionToken) {
            return;
        }

        /* let app component know that account information is available in the account service now */
        this.account.accountUpdated.next();
        url = this.account.object.adminAccount ? '/admin' : url;
        await this.router.navigateByUrl(url);

        return true;
    }

    /**
     * Update Qrcode
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public updateQrCode(): Promise<any> {
        return this.ajax.post('user/updateQrCode', { sessionToken: this.sessionToken }, '.content');
    }

    /**
     * checkGoogleCode
     *
     * @author    Romain van Maanen <romain@safira.nl>
     */
    public async checkGoogleCode(): Promise<boolean> {
        const data: any = { sessionToken: this.sessionToken, googleCode: this.googleCode };
        const res = this.ajax.post('user/checkGoogleCode', data, '.content');
        this.errorCode = !res;

        if (res) {
            this.storage.setStorage('authToken', this.sessionToken);
            this.googleCode = '';
            this.sessionToken = '';
            this.router.navigateByUrl('/dashboard').then();
        }

        return res;
    }

    /**
     * Login via Firebase
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    private async loginWithFirebase(provider: AuthProvider): Promise<any> {
        const credential = await signInWithPopup(this.afauth, provider);
        const token = await credential.user.getIdToken();
        const response = await this.ajax.post('user/loginWithFirebase', { idToken: token });
        // If login is valid
        if (response['error']['code'] === 0) {
            // Set login
            this.storage.setRelevantStorage(false);
            this.storage.setStorage('apiKey', this.API_KEY);
            this.sessionToken = '';

            if (response['use_two_factor_authentication']) {
                this.sessionToken = response['token'];
                this.skipBarcode = response['skipBarcode'];

                if (!this.skipBarcode) {
                    this.otpUrl = response['otpUrl'];
                }
            } else {
                this.storage.setStorage('authToken', response['token']);
                return this.account.get();
            }
        } else {
            this.setInfoItem('itemLogin.unknown_error');
        }
    }

    /**
     * update 2fa
     *
     * @author    Romain van Maanen <romain@safira.nl>
     */
    public updateTwoFactorAuthentication(userId: string, value: boolean): Promise<unknown> {
        return this.ajax.post('user/updateTwoFactorAuthentication', { userId, value }, '.main-container');
    }

    /**
     * reset 2fa
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public resetTwoFactorAuthentication(userId: string): Promise<any> {
        return this.ajax.post('user/resetTwoFactorAuthentication', { userId }, '.main-container');
    }

    /**
     * @returns    If Firebase is available.
     *
     * @author     Mike van Os <mike@safira.nl>
     */
    public firebaseAvailable(): boolean {
        return this.afauth != null;
    }

    /**
     * Send forgot password mail
     */
    public async generateResetPassword(email: string): Promise<unknown> {
        const appname =
            window.location.hostname === 'localhost'
                ? null
                : this.trimLastSlashFromUrl(this.locationStrategy.getBaseHref());
        if (this.util.empty(email)) {
            this.setInfoItem('itemLogin.no_email');
            return Promise.resolve();
        }
        const res: unknown = await this.ajax.post(
            'user/passwordForgot/generateResetPassword',
            { email, appname },
            '.login-body'
        );
        // Success
        if (res === true) {
            this.setInfoItem('itemLogin.password_recovery_email_sent', 'success');
        } else if (typeof res === 'string') {
            if (!this.router.url.includes('admin')) {
                window.location.href = res;
            }
        }
        return res;
    }

    /**
     * requestAccount
     *
     * Requests a user account
     */
    public async requestAccount(email: string): Promise<unknown> {
        const appname =
            window.location.hostname === 'localhost'
                ? null
                : this.trimLastSlashFromUrl(this.locationStrategy.getBaseHref());
        if (this.util.empty(email)) {
            this.setInfoItem('itemLogin.empty_fields');
            return;
        }
        const res: unknown = await this.ajax.post(
            'user/passwordForgot/requestAccount',
            { email, appname },
            '.content'
        );
        //Success
        if (res === true) {
            this.setInfoItem('itemLogin.account_request_sent', 'success');
        } else if (typeof res === 'string') {
            window.location.href = res;
        }
        return res;
    }

    /**
     * Send a email to the user to allow them to set their password
     */
    public async sendPasswordResetEmail(email: string): Promise<unknown> {
        const appname =
            window.location.hostname === 'localhost'
                ? null
                : this.trimLastSlashFromUrl(this.locationStrategy.getBaseHref());
        const res: unknown = await this.ajax.post(
            'user/passwordForgot/generateResetPassword',
            { email, appname },
            '.content'
        );
        // Success
        if (res === true) {
            this.toaster.success(
                'Er is een email naar de gebruiker verzonden waarmee ze hun wachtwoord kunnen herstellen'
            );
        } else if (typeof res === 'string') {
            alert('Kon geen e-mail versturen. Zend deze link handmatig naar de gebruiker: ' + res);
        }
        return res;
    }

    /**
     * Send a email to the user to allow them to set their password
     */
    public async sendWelcomeEmail(email: string): Promise<unknown> {
        const res: unknown = await this.ajax.post(
            'user/passwordForgot/sendNewUserPasswordEmail',
            { email },
            '.content'
        );
        // Success
        if (res === true) {
            this.toaster.success('Er is een email met informatie naar de gebruiker gestuurd.');
        } else if (typeof res === 'string') {
            alert('Kon geen e-mail versturen. Zend deze link handmatig naar de gebruiker: ' + res);
        }
        return res;
    }

    /**
     * register
     *
     * Registers an user if the requirements are being met
     */
    public async register(email: string, password: string, passwordRepeat: string): Promise<unknown> {
        const appname =
            window.location.hostname === 'localhost'
                ? null
                : this.trimLastSlashFromUrl(this.locationStrategy.getBaseHref());
        if (this.util.empty(email) || this.util.empty(password) || this.util.empty(passwordRepeat)) {
            this.setInfoItem('itemLogin.empty_fields');
            return;
        }
        if (this.validationService.checkErrors()) {
            return;
        }

        const res: unknown = await this.ajax.post('user/register/person', {
            email,
            password,
            passwordRepeat,
            appname,
        });
        // Success
        if (typeof res === 'string') {
            window.location.href = res;
        } else if (res['error']['code'] === 409) {
            this.setInfoItem('itemLogin.account_already_exists');
        } else {
            this.setInfoItem('itemLogin.account_created', 'success');
        }
        return res;
    }

    /**
     * activateUser
     *
     * Set an user activated
     */
    public async activateUser(hash: string, targetDiv?: any): Promise<unknown> {
        return await this.ajax.post('user/register/activate', { hash }, targetDiv).catch((msg) => {
            this.setInfoItem(msg);
            return;
        });
    }

    /**
     * unblockAccount
     *
     * Set an user activated
     */
    public async unblockAccount(resetToken: string, targetDiv?: any): Promise<unknown> {
        return await this.ajax.post('user/unblockAccount', { resetToken }, targetDiv).catch((msg) => {
            this.setInfoItem(msg);
            return;
        });
    }

    /**
     * resetPassword
     *
     * Reset user password
     */
    public async resetPassword(
        newPassword: string,
        newPasswordRepeat: string,
        hash: string,
        autoLogin?: boolean,
        isInviteLink? : boolean
    ): Promise<unknown> {
        if (this.util.empty(newPassword) || this.util.empty(newPasswordRepeat)) {
            this.setInfoItem('itemLogin.empty_fields');
            return;
        }

        const res: string | boolean = await this.ajax
            .post('user/passwordForgot/resetPassword', {
                newPassword,
                newPasswordRepeat,
                hash,
                returnEmail: autoLogin,
                isInviteLink
            })
            .catch((msg) => {
                this.setInfoItem(msg);
                return;
            });
        this.toaster.success('Wachtwoord is gewijzigd.');

        if(!autoLogin || typeof res === 'boolean')
            await this.router.navigate(['/login']);
        else
            await this.login(res, newPassword, true, "/dashboard")

        return res;
    }

    /**
     * checkResetPasswordHashAndTime
     *
     * Check if the reset password hash is still valid
     */
    public async checkResetPasswordHashAndTime(hash: string): Promise<unknown> {
        return await this.ajax
            .post('user/passwordForgot/checkResetPasswordHashAndTime', { hash })
            .catch((msg) => {
                this.setInfoItem(msg);
                return;
            });
    }

    /**
     * comparePasswords
     *
     * Check if the passwords are the same
     */
    public comparePasswords(password: string, passwordRepeat: string): boolean {
        if (
            (this.util.empty(password) && this.util.empty(passwordRepeat)) ||
            this.util.empty(passwordRepeat)
        ) {
            return false;
        }
        if (password !== passwordRepeat) {
            this.setInfoItem('itemLogin.confirm_password_no_match');
            return false;
        }
        return true;
    }

    /**
     * onPasswordSuccess
     *
     * Checks if the password is strong enough
     */
    public onPasswordSuccess(password: string, passwordRepeat: string): boolean {
        return this.comparePasswords(password, passwordRepeat);
    }

    /**
     * onPasswordError
     *
     * Throw error when password is not strong enough
     */
    public onPasswordError(): boolean {
        this.setInfoItem('itemLogin.stronger_password_required');
        return false;
    }

    /**
     * authTokenExists
     * Checks if an authToken exists in local or session storage
     * May be more appropriate in one of the auth services?
     * @returns    boolean
     */
    public authTokenExists(): boolean {
        return Boolean(this.storage.getStorage('authToken'));
    }

    /**
     * trimLastSlashFromUrl
     * Removes the last slash from the base href
     * @returns    string|null
     */
    trimLastSlashFromUrl(baseHref: string) {
        if (baseHref[baseHref.length - 1] == '/') {
            const trimmedHref = baseHref.substring(0, baseHref.length - 1);
            return trimmedHref;
        } else {
            return null;
        }
    }
}
