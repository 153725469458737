import { AuthenticateService } from './authenticate.service';
import { Injectable } from '@angular/core';
import { AuthorisationService } from './authorisation.service';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanDeactivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { AccountService } from '../user/account.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate, CanDeactivate<any> {
    public constructor(
        private auth: AuthorisationService,
        private AuthenticateService: AuthenticateService,
        private account: AccountService,
        private router: Router
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<UrlTree | boolean> {
        return this.canNavigate(route);
    }

    public canDeactivate(
        component,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        next?: RouterStateSnapshot
    ): Promise<UrlTree | boolean> {
        return this.canNavigate(route);
    }

    private writeRedirect(url: string) {
        sessionStorage.setItem('redirectUrl', url);
    }

    private async canNavigate(route: ActivatedRouteSnapshot): Promise<UrlTree | boolean> {
        return new Promise((resolve) => {
            const redirect = this.router.parseUrl('/403');

            this.auth
                .checkIfAuthTokenIsValid()
                .then(async (canAccess: boolean) => {
                    if (!canAccess) {
                        // user is not logged in but was redirected to another page.
                        // save the url in localstorage so the login component can redirect after login.
                        let url = route['_routerState']['url'];
                        if (url != '') {
                            this.writeRedirect(url);
                        }
                        return null;
                    }
                    if (this.account.object) {
                        return Promise.resolve(this.account.object);
                    }
                    let get = this.account.get();
                    let isRejected;
                    await get.catch(() => {
                        isRejected = true;
                    });
                    return isRejected ? undefined : get;
                })
                .then((info) => {
                    if (!info) {
                        this.AuthenticateService.logout();
                        // user.logout already redirects
                        resolve(false);
                    }

                    if (info.adminAccount && route.routeConfig.path == 'overview') {
                        this.router.navigateByUrl('/admin');
                    }
                    if (info.adminAccount || !this.routeIsAdmin(route)) {
                        resolve(true);
                    } else {
                        resolve(redirect);
                    }
                })
                .catch(() => {
                    resolve(redirect);
                });
        });
    }

    private routeIsAdmin(route: ActivatedRouteSnapshot, parentIsAdmin: boolean = false) {
        if (!route.firstChild) {
            if ('admin' in route.data) {
                return route.data.admin === true;
            }
            return parentIsAdmin;
        }
        if ('admin' in route.data) {
            return this.routeIsAdmin(route.firstChild, route.data.admin === true);
        }

        return this.routeIsAdmin(route.firstChild, parentIsAdmin);
    }
}
