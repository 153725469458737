import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../../core/services/user/account.service';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
    constructor(private account: AccountService, private router: Router) {}

    async ngOnInit() {
        if (!this.account.object) {
            await this.account.get();
        }

        if (this.account.object.adminAccount) {
            await this.router.navigateByUrl('/admin');
        }
    }
}
