import { MobileMenuService } from './core/services/mobile-menu.service';
import {
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    AfterViewChecked,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigService } from './core/services/config.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { AjaxService } from './core/services/ajax.service';
import { NavigationService } from './core/services/navigation.service';
import { Breakpoint, ResponsiveService } from './core/services/responsive.service';
import { AuthenticateService } from './core/services/auth/authenticate.service';
import { LanguageService } from './core/services/language.service';
import { TranslationService } from './core/services/translation.service';
import { ThemeService } from './core/services/theme.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
    login: boolean = null;
    private destroyed$: ReplaySubject<void> = new ReplaySubject(1);
    private registered: boolean = false;
    public devicegrade: string;

    constructor(
        public navigation: NavigationService,
        public config: ConfigService,
        public responsive: ResponsiveService,
        private router: Router,
        private translation: TranslationService,
        private language: LanguageService,
        private ajax: AjaxService,
        private authenticate: AuthenticateService,
        private theme: ThemeService,
        public mobileMenuService: MobileMenuService,
        private cdRef: ChangeDetectorRef
    ) {

        responsive.onResize(window.innerWidth);

        router.events
            .pipe(takeUntil(this.destroyed$))
            .pipe(filter((e) => e instanceof NavigationEnd))
            .subscribe(() => {
                const data: any = router.routerState.snapshot.root.firstChild.data;
                this.login = data.login === true;

            });
        this.devicegrade = this.responsive.getGrade();
    }

    @HostListener('window:resize')
    onResize() {
        this.responsive.onResize(window.innerWidth);
        this.devicegrade = this.responsive.getGrade();
    }

    async ngOnInit() {
        this.registerBreakpoints();

        await this.language.setCurrentLanguage();
        await this.authenticate.checkIsSafira();
        await this.translation.retrieveTranslations();
    }

    private registerBreakpoints() {
        if (this.registered) {
            return;
        }

        this.registered = true;

        for (const size in Breakpoint) {
            if (+size) {
                document.documentElement.style.setProperty(
                    '--' + Breakpoint[size].toLowerCase(),
                    +size - 1 + 'px'
                );
            }
        }
    }

    public mobileBarCheck() {
        return this.mobileMenuService.getMenuItems().length > 0
            ? this.devicegrade == 'SM' || this.devicegrade == 'XS' || this.devicegrade == 'XXS'
                ? true
                : false
            : false;
    }

    navCycle() {
        this.navigation.sideNav = !this.navigation.sideNav;
        this.navigation.resetMenu();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
}