import { Injectable } from '@angular/core';
import { UserModel } from '../models/user/user.model';
import { AjaxService } from './ajax.service';
import { HttpStatusCode } from '../models/http-status-code';
import { CrudException } from '../models/crud/crud-exception.model';
import { CrudServiceModel } from '../models/crud/crud-service.model';

@Injectable({
    providedIn: 'root',
})
export class ImportService extends CrudServiceModel<UserModel> {
    public duplicates;

    protected readonly create = UserModel;
    protected readonly module: string = 'user';

    public userModels: UserModel[] = [];
    public importableModels: UserModel[] = [];
    public status: any[] = [];

    public constructor(protected ajax: AjaxService) {
        super();
    }

    public validator(object: UserModel) {
        if (
            object.email === '' ||
            (object.email === undefined && object.firstName === '') ||
            (object.firstName === undefined && object.surname === '') ||
            (object.surname === undefined && object.companyName === '')
        ) {
            object.email = '';
            object.firstName = '';
            object.surname = '';
            throw new CrudException(HttpStatusCode.BAD_REQUEST, 'itemUser.error_information_missing');
        }
        if (object.email === '' || object.email === null) {
            object.email = '';
            throw new CrudException(HttpStatusCode.BAD_REQUEST, 'itemUser.error_email_required');
        }
        if (object.firstName === '' || object.firstName === null) {
            object.firstName = '';
            throw new CrudException(HttpStatusCode.BAD_REQUEST, 'itemUser.error_firstname_required');
        }
        if (object.surname === '' || object.surname === null) {
            object.surname = '';
            throw new CrudException(HttpStatusCode.BAD_REQUEST, 'itemUser.error_surname_required');
        }
        if (
            !object.email.match(
                /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            )
        ) {
            throw new CrudException(HttpStatusCode.BAD_REQUEST, 'itemUser.error_email_not_valid');
        }
        if (this.duplicates.find((data) => data.email === object.email)) {
            throw new CrudException(HttpStatusCode.BAD_REQUEST, 'itemUser.error_duplicate_email_found');
        }
        return true;
    }

    protected validation(object: UserModel): boolean {
        return this.validator(object);
    }

    async getUsersWithBatch() {
        let results = await this.getEndpoint('getUsersWithBatch');
        return results.map((model) => {
            return new this.create(this.save.bind(this), model);
        });
    }

    async checkDuplicateEmail(object: []) {
        this.duplicates = await this.getEndpoint('checkDuplicateEmail', { emails: object });
    }

    sendEmail(object: string[]) {
        this.getEndpoint('/passwordForgot/sendNewUserPasswordEmail', { emails: object });
    }
}
