import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service';
import { ToasterService } from './toaster.service';

export namespace VisionWs {
    export const PRODUCT_CODE_MARKIES = '35000';
    export const ARTICLE_CODE_MARKIES = 'P' + VisionWs.PRODUCT_CODE_MARKIES;

    export interface IBaseResponse {
        error_code: number;
        error_text: string;
        ws_result?: number;
    }

    export interface ICartResponse extends IBaseResponse {
        orderTableData: VisionWs.IProductFeature[];
        globalOrderTableData: VisionWs.IProductFeature[];
        extraOptions: VisionWs.IProductFeature[];
    }

    export interface IArticle {
        article_code: string;
        article_desc: string;
        article_sales_unit_price: number;
        product_code: string;
        sales_unit_price_incl_vat: number;
    }

    export interface IGetArticleOptions {
        filter_article_code?: string;
        filter_article_code_wc?: string;
        filter_article_description?: string;
        filter_article_description_wc?: string;
        filter_article_is_product?: number;
    }

    export interface ArticleResponse extends IBaseResponse {
        article: IArticle[];
    }

    export interface IProductFeature {
        pf_code: string;
        pf_desc: string;
        pf_name: string;
        pf_label: string;
        parent_code: string;
        info?: string;
        maxHeight?: number;
        options: IExtraOptions[];
        hidden: boolean;
        disabled: boolean;
        note: string;
        error: boolean;
        step_id: number;
        feature_type: number;
        entry_editable: number;
        unit_type: number;
        presentation_order: number;
        min_value: number;
        max_value: number;
        remark: string;
        product_options: IProductOption[];
        active: boolean;
    }

    export interface IExtraOptions {
        code: string;
        description: string;
    }

    export interface IGetProductFeatureOptions {
        product_code: string;
        filter_feature_code?: string;
        filter_feature_description_wc?: string;
    }

    export interface IProductFeatureResponse extends IBaseResponse {
        product_feature: IProductFeature[];
    }

    export interface IProductOption {
        code: string;
        description: string;
        num_value: number;
        minimum_num_value: number;
        maximum_num_value: number;
        info?: string;
        maxHeight?: number;
        remark?: string;
        blocked_attention_remark?: string;
    }

    export interface IProductOptionReq {
        feature_code: string;
        option_code?: string;
        num_value?: number;
        option_description?: string;
    }

    export interface IGetProductOptionOptions {
        product_code: string;
        feature_code: string;
        article_code?: string;
        filter_txt?: string;
        is_offer?: number;
        maximum_number_of_options?: number;
        product_option_list?: IProductOptionReq[];
    }

    export interface IProductOptionResponse extends IBaseResponse {
        option_list: IProductOption[];
        info?: string;
        maxHeight?: number;
    }

    export interface IGetSalesPriceOptions {
        article_code: string;
        sales_quantity?: number;
        no_product_option_ok?: number;
        product_option_list?: IProductOptionReq[];
    }

    export interface SalesPriceResponse extends IBaseResponse {
        bruto: {
            rows: number[];
            subtotal: number;
            vat_total: number;
            grand_total: number;
        };
        netto: {
            rows: number[];
            subtotal: number;
            vat_total: number;
            grand_total: number;
        };
    }

    export interface IImportedFile {
        table_name: number;
        insert_count: number;
        update_count: number;
        skip_count: number;
        insert_array?: number[];
        update_array?: number[];
    }

    export interface IImportFileOptions {
        ws_code: number;
        text_file: string;
    }

    export interface IImportedFileResponse extends IBaseResponse {
        table_import_info: IImportedFile[];
    }

    export interface ExportFileOptions {
        ws_code: number;
        record_search_field_name: string;
        record_search_field_value: string;
    }

    export interface IExportFileResponse extends IBaseResponse {
        export_row_count: number;
        export_file_txt: string;
    }
}

@Injectable({
    providedIn: 'root',
})
export class IspvisionService {
    constructor(private ajax: AjaxService, private toaster: ToasterService) {}

    public error: string;

    /**
     * complete the order and send the orderdata to the api
     *
     * @author     Bas Hoppenbrouwers
     * @param      data    JSON
     * @returns    Promise
     */
    public async sendOrder(data: any) {
        try {
            const res = await this.ajax.post('ispVision/importOrder', data);
            if (res.errors) {
                this.toaster.error(res.message);
            } else {
                this.toaster.success(res.message);
            }
            return res;
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * clearShoppingCart
     *
     * @author     Arjan Koemans
     * @param      data    JSON
     * @returns    Void
     */
    public async clearShoppingCart() {
        try {
            await this.ajax.get('ispVision/clearShoppingCart');
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * getOrders
     *
     * @author     Arjan Koemans
     * @returns    Promise
     */
    public async getOrders() {
        try {
            const res = await this.ajax.get('ispVision/getOrders');
            return res;
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * getOrders
     *
     * @author     Arjan Koemans
     * @returns    Promise
     */
    public async importDealers() {
        try {
            await this.ajax.get('ispVision/cronjobImportDealers');
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * getArticle
     * ISP Vision WS get article function
     * ws_function("GET_ARTICLE")
     *
     * @author     Bas Hoppenbrouwers <bas@safira.nl>
     * @param      options    GetArticleOptions
     * @returns    Promise
     */
    public async getArticle(options: VisionWs.IGetArticleOptions) {
        try {
            const res = (await this.ajax.post('ispVision/getArticle', { data: options })) as
                | VisionWs.ArticleResponse
                | VisionWs.IBaseResponse;

            if (res.error_code < 0) {
                this.toaster.error(res.error_text);
                return res;
            }

            return res;
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * getProductFeature
     * ISP Vision WS get product feature function
     * ws_function("GET_PRODUCT_FEATURE")
     *
     * @author     Bas Hoppenbrouwers <bas@safira.nl>
     * @param      options    GetProductFeatureOptions
     * @returns    Promise
     */
    public async getProductFeature(options: VisionWs.IGetProductFeatureOptions) {
        try {
            const res = (await this.ajax.post('ispVision/getProductFeature', {
                data: options,
            })) as VisionWs.IProductFeatureResponse | VisionWs.IBaseResponse;

            if (res.error_code < 0) {
                this.toaster.error(res.error_text);
                return res;
            }

            return res;
        } catch (error) {
            this.toaster.error(error);
        }
    }

    public resetPopup() {
        this.error = null;
    }

    public async getShoppingCart() {
        try {
            const res = (await this.ajax.get('ispVision/getShoppingCart')) as VisionWs.ICartResponse;

            if (res.error_code < 0) {
                this.toaster.error(res.error_text);
                return res;
            }

            return res;
        } catch (error) {
            this.toaster.error(error);
        }
    }

    public async updateShoppingCart(
        orderTableData: VisionWs.IProductFeature[],
        globalOrderTableData: VisionWs.IProductFeature[]
    ) {
        try {
            await this.ajax.post('ispVision/updateShoppingCart', {
                orderTableData,
                globalOrderTableData,
            });
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * getProductOption
     * ISP Vision WS get product option function
     * ws_function("GET_PRODUCT_OPTION")
     *
     * @author     Bas Hoppenbrouwers <bas@safira.nl>
     * @param      options    GetProductOptionOptions
     * @returns    Promise
     */
    public async getProductOption(options: VisionWs.IGetProductOptionOptions) {
        try {
            const res = (await this.ajax.post('ispVision/getProductOption', { data: options })) as
                | VisionWs.IProductOptionResponse
                | VisionWs.IBaseResponse;

            if (res.error_code < 0) {
                this.toaster.error(res.error_text);
                return res;
            }
            return res;
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * getItemPrices
     * ISP Vision WS get sales price function
     * ws_function("GET_SALES_PRICE")
     *
     * @author     Bas Hoppenbrouwers <bas@safira.nl>
     * @param      options    GetSalesPriceOptions
     * @returns    Promise
     */
    public async getItemPrices(globalOrderTableData: any, orderTableData: any) {
        try {
            const res = (await this.ajax.post('ispVision/getSalesPrice', {
                globalOrderTableData,
                orderTableData,
            })) as VisionWs.SalesPriceResponse;

            if (res.error_code < 0) {
                this.toaster.error(res.error_text);
                return res;
            }

            return res;
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * importFile
     * ISP Vision WS import file function
     * ws_function("IMPORT_FILE")
     *
     * @author     Bas Hoppenbrouwers <bas@safira.nl>
     * @param      options    ImportFileOptions
     * @returns    Promise
     */
    public async importFile(options: VisionWs.IImportFileOptions) {
        try {
            const res = (await this.ajax.post('ispVision/importFile', { data: options })) as
                | VisionWs.IImportedFileResponse
                | VisionWs.IBaseResponse;

            if (res.error_code < 0) {
                this.toaster.error(res.error_text);
                return res;
            }

            return res;
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * exportFile
     * ISP Vision WS export file function
     * ws_function("EXPORT_FILE")
     *
     * @author     Bas Hoppenbrouwers <bas@safira.nl>
     * @param      options    ExportFileOptions
     * @returns    Promise
     */
    public async exportFile(options: VisionWs.ExportFileOptions) {
        try {
            const res = (await this.ajax.post('ispVision/exportFile', { data: options })) as
                | VisionWs.IExportFileResponse
                | VisionWs.IBaseResponse;

            if (res.error_code < 0) {
                this.toaster.error(res.error_text);
                return res;
            }

            return res;
        } catch (error) {
            this.toaster.error(error);
        }
    }
}
