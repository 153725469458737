import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ActivateComponent } from './activate/activate.component';
import { UnblockComponent } from './unblock/unblock.component';
import { ModuleWithProviders } from '@angular/core';
import { SetPasswordComponent } from './set-password/set-password.component';
import { WelcomeComponent } from './welcome/welcome.component';

export const authenticateRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: {
            login: true,
        },
    },
    {
        path: 'forgotPassword/:hash',
        component: ForgotPasswordComponent,
        data: {
            login: true,
        },
    },
    {
        path: 'welcome/:hash',
        component: WelcomeComponent,
        data: {
            login: true,
        },
    },
    {
        path: 'setPassword/:hash',
        component: SetPasswordComponent,
        data: {
            login: true,
        },
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: {
            login: true,
        },
    },
    {
        path: 'activate/:hash',
        component: ActivateComponent,
        data: {
            login: true,
        },
    },
    {
        path: 'forgot',
        component: ForgotComponent,
        data: {
            login: true,
        },
    },
    {
        path: 'unblockAccount/:token',
        component: UnblockComponent,
        data: {
            login: true,
        },
    },
];

export const authenticateRouting: ModuleWithProviders<RouterModule> =
    RouterModule.forChild(authenticateRoutes);
