import { Component } from '@angular/core';
import { AuthenticateService } from '../../../core/services/auth/authenticate.service';

@Component({
    selector: 'sLoginWrapper',
    templateUrl: './login-wrapper.component.html',
    styleUrls: ['./login-wrapper.component.less'],
})
export class LoginWrapperComponent {
    constructor(public authenticate: AuthenticateService) {}

    get isMobileDevice(): boolean {
        var windowWidth =
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        return windowWidth < 768;
    }
}
