import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticateService } from 'src/app/core/services/auth/authenticate.service';
import { AuthorisationService } from 'src/app/core/services/auth/authorisation.service';

@Component({
	selector: 'welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.less']
})
export class WelcomeComponent implements OnInit {
	public newPassword: string = '';
	public newPasswordRepeat: string = '';
	public resetErrorId: string = 'reset-error';
	public sendPassword: boolean = false;
	public validHash: boolean = null;
	public hash: string = null;
	public canSubmit: boolean = false;

	public paramSubscription: Subscription = null;

	public constructor(
		public authenticate: AuthenticateService,
		private route: ActivatedRoute,
		private auth: AuthorisationService,
		private router: Router
	) {
		if (this.auth.canAccess()) {
			this.router.navigate(['/dashboard']);
		}
	}

	public ngOnInit(): void {
		this.paramSubscription = this.route.params.subscribe((params: Params) => {
			this.hash = params['hash'];

			this.authenticate.checkResetPasswordHashAndTime(this.hash).then((result: boolean) => {
				this.validHash = result;
			});
		});
	}

	public ngOnDestroy(): void {
		this.paramSubscription?.unsubscribe();
	}
}
