import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticateService } from '../../../core/services/auth/authenticate.service';
import { AuthorisationService } from '../../../core/services/auth/authorisation.service';
import { UtilService } from '../../../core/services/util.service';

@Component({
    selector: 'forgot',
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.less'],
})
export class ForgotComponent {
    public forgotPasswordEmail: string = '';
    public forgotErrorId: string = 'forgot-error';
    public forgotSuccessId: string = 'forgot-success';
    public success: string = '';
    public nextUrl: string;

    constructor(
        public authenticate: AuthenticateService,
        public router: Router,
        public route: ActivatedRoute,
        public util: UtilService,
        private auth: AuthorisationService
    ) {
        if (this.auth.canAccess()) {
            this.router.navigate(['/dashboard']).then();
        }

        route.queryParams.subscribe((query: Params) => (this.nextUrl = query['next']));
    }

    async generatePassword() {
        await this.authenticate.generateResetPassword(this.forgotPasswordEmail);
    }
}
