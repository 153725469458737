import {
    ChangeDetectorRef,
    Component,
    forwardRef,
    HostListener,
    Input,
    ViewContainerRef,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'sTextarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextareaComponent),
            multi: true,
        },
    ],
})
export class TextareaComponent implements OnInit, ControlValueAccessor {
    @Input('id') public id: string = '';

    //holds the name of the input
    @Input('label') public label: string = '';

    //holds the name of the input
    @Input('name') public name: string = '';

    //holds the readonly state
    @Input('required') public required: boolean = false;

    //holds the disabled state
    @Input('disabled') public disabled: boolean = false;

    //holds the readonly state
    @Input('readonly') public readonly: boolean = false;

    //holds the placeholder
    @Input('placeholder') public placeholder: string = '';

    //holds the maxlength
    @Input('maxLength') public maxLength: number;

    //holds the name of the input
    @Input('change') private _functionNameKeyUp: string;

    //holds the classes of the textarea
    @Input('sClass') public sClass: string = '';

    //holds the registered Forms API functions
    public onChange = (val: any) => {};
    public onTouched = () => {};

    //holds the value
    public valueLength: number = 0;

    // The height of the textarea
    public height: number = 67;

    //holds the value
    private _value: string = '';
    @Input('value') public set value(val: string) {
        if (val) {
            this._value = val;
        }
    }
    public get value(): string {
        return this._value;
    }

    /**
     * HostListener keyup
     *
     * update the input on keyup
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    @HostListener('keyup', ['$event'])
    public keyup(event: KeyboardEvent): void {
        this.update(event);

        if (typeof this._functionNameKeyUp !== 'undefined') {
            this.getParentComponent()[this._functionNameKeyUp](event);
        }
    }

    /**
     * constructor
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public constructor(private viewContainerRef: ViewContainerRef, private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.valueLength = this.value == '' ? 0 : this.value.length;
    }

    /**
     * update
     *
     * Updates the value of the input
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    private update(event: KeyboardEvent): void {
        this.value = (<HTMLTextAreaElement>event.target).value;
        this.valueLength = this.value.length;
    }

    /**
     * registerOnChange
     *
     * Registers the onChange function
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }

    /**
     * registerOnTouched
     *
     * Registers the onTouch function
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * writeValue
     *
     * Writes the value
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public writeValue(value: string): void {
        this.value = value;
    }

    public onInput(event: Event): void {
        const textarea: HTMLTextAreaElement = <HTMLTextAreaElement>event.target;
        const style: CSSStyleDeclaration = window.getComputedStyle(textarea);

        this.height = 5;
        this.cdRef.detectChanges();
        this.height =
            textarea.scrollHeight +
            Math.ceil(parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth));
    }

    private getParentComponent(): any {
        return this.viewContainerRef['_data'].componentView.component.viewContainerRef['_view'].component;
    }
}
