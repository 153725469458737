import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { ConfiguratorComponent } from './configurator/configurator.component';
import { AuthGuardService } from '../../core/services/auth/auth-guard.service';

export const configuratorRoutes: Routes = [
    {
        path: 'configurator',
        component: ConfiguratorComponent,
        canActivate: [AuthGuardService],
    },
];

export const configuratorRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(configuratorRoutes);
