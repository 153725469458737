import { Injectable } from '@angular/core';
import { item } from '../models/item.model';
import { AccountService } from './user/account.service';
import { StorageService } from './storage.service';
import { Breakpoint, ResponsiveService } from './responsive.service';
import { DropdownIndicator } from '../models/action.model';
import { AuthenticateService } from './auth/authenticate.service';
import { LanguageService } from './language.service';
import { TranslationService } from './translation.service';
import { themes, ThemeService } from './theme.service';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    // Placement of the navigation
    sideNav: boolean = false;
    menu: item[];
    gapMenu: item[];
    submenu: boolean = false;
    readonly breakpoint: number = Breakpoint.LG;

    private hours = new Date().getHours();

    constructor(
        private responsive: ResponsiveService,
        private account: AccountService,
        private authenticate: AuthenticateService,
        private storage: StorageService,
        private theme: ThemeService,
        private language: LanguageService,
        private translation: TranslationService,
        private config: ConfigService
    ) {
        this.account.accountUpdated.subscribe(() => {
            this.resetMenu();
        });
        this.theme.changed.subscribe(() => {
            this.resetMenu();
        });
        this.language.languageChange.subscribe(() => {
            this.resetMenu();
        });
    }

    setSubmenu(submenu: item[]) {
        this.menu = submenu;
        this.submenu = true;
    }

    resetMenu() {
        this.menu = this.getMenu();
        this.gapMenu = this.getGapMenu();
        this.submenu = false;
    }

    private getMenu(): item[] {
        return [
            {
                type: 'text',
                text: 'Bestelformulier',
                show: true,
                action: {
                    type: 'route',
                    route: '/configurator',
                    activeBackground: 'neutral',
                    exactLink: true,
                },
            },
            {
                type: 'text',
                text: 'Orderoverzicht',
                show: true,
                action: {
                    type: 'route',
                    route: '/orders',
                    activeBackground: 'neutral',
                    exactLink: true,
                },
            },
            {
                type: 'text',
                text: 'itemMenu.users',
                icon: 'user-friends',
                show: this.isAdmin(),
                action: { type: 'route', route: 'admin/users', activeBackground: 'neutral' },
            },
            {
                type: 'text',
                text: 'itemMenu.settings',
                icon: 'cog',
                show: this.isAdmin(),
                action: {
                    type: 'dropdown',
                    width: 'relative',
                    indicator: this.getIndicator(),
                    openOnHover: this.openOnHover(),
                    items: [
                        {
                            type: 'text',
                            text: 'itemMenu.translations',
                            icon: 'language',
                            show: this.isAdmin(),
                            action: {
                                type: 'route',
                                route: 'admin/translations',
                                activeBackground: 'neutral',
                            },
                        },
                        {
                            type: 'text',
                            text: 'itemMenu.2fa',
                            icon: 'phone-laptop',
                            show: this.isAdmin(),
                            action: {
                                type: 'route',
                                route: 'admin/two-factor-authentication',
                                activeBackground: 'neutral',
                            },
                        },
                    ],
                },
            },
        ];
    }

    private getGapMenu(): item[] {
        return [
            {
                type: 'text',
                show: this.language.available && this.language.available.length > 1,
                text:
                    this.language.current === undefined
                        ? 'itemTranslation.auto'
                        : this.hasRoom()
                        ? 'itemTranslation.' + this.language.current.iso.toLowerCase()
                        : this.language.current.iso.toUpperCase(),
                action: {
                    type: 'dropdown',
                    width: 'relative',
                    indicator: this.getIndicator(),
                    openOnHover: this.openOnHover(),
                    items: this.getAvailableLanguages(),
                },
            },
            {
                type: 'text',
                show: this.account.object !== undefined,
                text: [
                    'itemMenu.' + this.getHours(),
                    ' ',
                    this.account.object === undefined ? null : this.account.object.firstName,
                ],
                icon: 'user',
                action: {
                    type: 'dropdown',
                    width: 'relative',
                    indicator: this.getIndicator(),
                    openOnHover: this.openOnHover(),
                    items: [
                        {
                            type: 'text',
                            text: 'itemMenu.account',
                            icon: 'user-circle',
                            action: { type: 'route', route: 'account', activeBackground: 'neutral' },
                        },
                        {
                            type: 'text',
                            text: 'itemMenu.back_to_admin',
                            icon: 'user-shield',
                            show: this.storage.getStorage('adminToken') !== '',
                            action: {
                                type: 'click',
                                click: async () => {
                                    this.authenticate.goBackToAdmin();
                                },
                            },
                        },
                        {
                            type: 'text',
                            text: 'global_logout',
                            icon: 'sign-out-alt',
                            action: {
                                type: 'click',
                                click: async () => {
                                    await this.authenticate.logout();
                                },
                            },
                        },
                    ],
                },
            },
        ];
    }

    private getAvailableThemes(): item[] {
        return themes
            .filter((theme) => theme.name !== this.theme.current.name)
            .map((theme) => {
                return {
                    type: 'text',
                    text: this.hasRoom() ? 'itemMenu.' + 'theme_' + theme.name : null,
                    icon: theme.icon,
                    action: {
                        type: 'click',
                        click: () => {
                            this.theme.setTheme(theme.name);
                        },
                    },
                };
            });
    }

    private getAvailableLanguages(): item[] {
        if (this.language.available === undefined) {
            return [];
        }

        const languages: item[] = [];

        for (const language of this.language.available) {
            if (this.language.current.languageId === language.languageId) {
                continue;
            }

            languages.push({
                type: 'text',
                text: 'itemTranslation.' + language.iso,
                action: {
                    type: 'click',
                    click: async () => {
                        await this.language.setCurrentLanguage(language);
                        await this.translation.retrieveTranslations();
                    },
                },
            });
        }
        return languages;
    }

    private isAdmin(): boolean {
        return this.account.object !== undefined && this.account.object.adminAccount;
    }

    private hasRoom(): boolean {
        return this.sideNav || !this.responsive.isSize(this.breakpoint);
    }

    private openOnHover(): boolean {
        return !this.sideNav && this.responsive.isSize(this.breakpoint);
    }

    private getIndicator(): DropdownIndicator {
        return {
            icon: !this.responsive.isSize(this.breakpoint) || this.sideNav ? 'chevron-right' : 'chevron-down',
            justifyContent: 'space-between',
        };
    }

    private getHours(): string {
        switch (true) {
            case this.hours < 6:
                return 'good_night';
            case this.hours < 12:
                return 'good_morning';
            case this.hours < 18:
                return 'good_afternoon';
            case this.hours <= 23:
                return 'good_evening';
        }
    }
}
