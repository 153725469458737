import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation.component';
import { ItemModule } from '../item/item.module';
import { TranslationModule } from '../../../modules/translate.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [NavigationComponent],
    imports: [CommonModule, ItemModule, TranslationModule, RouterModule],
    exports: [NavigationComponent],
})
export class NavigationModule {}
