import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfiguratorComponent } from './configurator/configurator.component';
import { configuratorRouting } from './configurator.routes';
import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { PopupModule } from '../../core/components/popup/popup.module';
import { ClickOutsideDirective } from '../../core/directives/click-outside/click-outside.directive';

@NgModule({
    declarations: [ConfiguratorComponent, ClickOutsideDirective],
    imports: [configuratorRouting, CommonModule, PopupModule, FormsModule, FormModule],
})
export class ConfiguratorModule {}
