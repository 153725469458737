import { RouterModule, Routes } from '@angular/router';
import { UserFormComponent } from '../admin/user/user-form/user-form.component';
import { GeneralComponent } from '../admin/user/user-form/general/general.component';
import { AddressComponent } from '../admin/user/user-form/address/address.component';
import { RightsComponent } from '../admin/user/user-form/rights/rights.component';
import { UserComponent } from '../admin/user/user.component';

export const accountRoutes: Routes = [
    {
        path: 'account',
        component: UserComponent,
        children: [
            {
                path: '',
                component: UserFormComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'general',
                        pathMatch: 'full',
                    },
                    {
                        path: 'general',
                        component: GeneralComponent,
                    },
                    {
                        path: 'address',
                        component: AddressComponent,
                    },
                    {
                        path: 'rights',
                        component: RightsComponent,
                    },
                ],
            },
        ],
    },
];

export const accountRouting = RouterModule.forChild(accountRoutes);
