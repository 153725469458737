import { Component } from '@angular/core';
import { ToasterService } from '../../../core/services/toaster.service';
import { AuthorisationService } from '../../../core/services/auth/authorisation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateService } from '../../../core/services/auth/authenticate.service';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.less'],
})
export class RegisterComponent {
    public password: string = '';
    public passwordRepeat: string = '';
    public email: string = '';
    public requestErrorId = 'request-error';
    public requestSuccessId = 'request-success';
    public registerErrorId = 'register-error';
    public registerSuccessId = 'register-success';

    public error: boolean = false;
    public register: boolean = false;
    public emailCheck: boolean = false;
    public canSubmit: boolean = false;

    public constructor(
        public authenticate: AuthenticateService,
        public auth: AuthorisationService,
        private router: Router
    ) {
        if (this.auth.canAccess()) {
            this.router.navigate(['/dashboard']);
        }
    }
}
