import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.less'],
})
export class OrderDetailsComponent implements OnInit {
    constructor(private activatedRoute: ActivatedRoute) {}
    public orderId: number;

    ngOnInit(): void {
        this.orderId = +this.activatedRoute.snapshot.paramMap.get('orderId');
    }
}
