import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailsComponent } from '../order-details/order-details.component';

import { AuthGuardService } from '../../core/services/auth/auth-guard.service';

export const ordersRoutes: Routes = [
    {
        path: 'orders',
        component: OrdersComponent,
    },
    {
        path: 'orders/:orderId',
        component: OrderDetailsComponent,
    },
];

export const ordersRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(ordersRoutes);
