import { Subscription } from 'rxjs';
import { UserGroupModel } from '../../../../../core/models/user/user-group.model';
import { UserService } from '../../../../../core/services/user/user.service';
import { UserGroupService } from '../../../../../core/services/user/user-group.service';
import { Router } from '@angular/router';
import { ToasterService } from '../../../../../core/services/toaster.service';
import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../../../core/services/user/account.service';

@Component({
    selector: 'rights',
    templateUrl: './rights.component.html',
    styleUrls: ['./rights.component.less'],
})
export class RightsComponent implements OnInit {
    public userGroups: UserGroupModel[];
	private subscription: Subscription;

    constructor(
        public userService: UserService,
        public userGroupService: UserGroupService,
        private toaster: ToasterService,
        private router: Router
    ) { }
	async ngOnInit() {
		await this.getGroups();
	}

	public async getGroups(){
		let groups = await this.userGroupService.getAll();
		this.userGroups = this.router.url.includes('/sub-accounts/') ? groups.filter(group => !group.adminGroup) : groups;
		this.userGroups.forEach(group => {
			let res = this.userService.object.userGroups.find(userGroup => userGroup.groupId == group.groupId)
			if(res){
				group.active = res.active;
			}
		});
	}

	public checkUpdate(group: UserGroupModel, $event: MouseEvent){
		if (group.active && this.userService.object.userGroups.filter((group) => group.active).length <= 1) {
            this.toaster.error('itemUser.minimum_user_groups');
			$event.preventDefault();
        }
	}
	
    public updateGroups(group: UserGroupModel) {
		this.userService.object.userGroups.find(userGroup => userGroup.groupId == group.groupId).active = Number(group.active);
    }
}
