import { Component, Input, forwardRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'sAltselect',
    templateUrl: './altselect.component.html',
    styleUrls: ['./altselect.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AltselectComponent),
            multi: true,
        },
    ],
})
export class AltselectComponent implements ControlValueAccessor {
    @Input('value') private _value: string = '';

    /** The visible name of the selector */
    @Input('label') public label: string = '';

    /** Whether an option needs to be selected */
    @Input('required') public required: any = null;

    /** The disabled state, `null`, `undefined` or `false` means it's not disabled */
    @Input('disabled') public disabled: any = null;

    /** The classes of the selector */
    @Input('sClass') public sClass: string = '';

    /** The options the user can select */
    @Input('options') public options: Array<{ id: string; value: string }> = null;

    @Input('noResults') public noResults: string = '';

    @ViewChild('input', { static: false }) private inputElement: ElementRef;

    public get value(): string {
        return this._value;
    }

    // Hooks from Angular
    public onChange: (val: any) => void = () => {};
    public onTouched: () => void = () => {};

    /** The `<select>` element */
    @ViewChild('select', { static: false }) private select: ElementRef;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    public searchOption: string = '';
    public placeholderValue: string = null;
    public filteredOptions: any[] = [];
    public highlightOption: number = 0;

    ngAfterViewChecked() {
        this.checkElementVisibility();
    }

    public get searchRequest(): string {
        if (this.value) {
            const option = this.options.find((option) => option.id == this.value);
            if (option) {
                this.searchOption = option.value;
            }
        }
        return this.searchOption;
    }

    public handleFocus(event: Event): void {
        const keyboardEvent = event as KeyboardEvent;
        switch (keyboardEvent.key) {
            case 'ArrowDown':
                this.highlightOption++;
                break;
            case 'ArrowUp':
                this.highlightOption--;
                break;
            case 'Enter':
                this.choose(this.filteredOptions[this.highlightOption]);
                event.target['blur']();
                break;
        }
        if (this.highlightOption >= this.filteredOptions.length) {
            this.highlightOption = this.filteredOptions.length - 1;
        } else if (this.highlightOption < 0) {
            this.highlightOption = 0;
        }
    }

    public onSearchChange(searchTerm: string): void {
        this.searchOption = searchTerm.toLowerCase(); // Converteer de zoekterm naar kleine letters
        this.filterOptions();
    }

    public filterOptions(): void {
        const searchTerm = this.searchOption.toLowerCase();
        // Sorteer de resultaten op basis van het begin van de waarde
        this.filteredOptions = this.options
            .filter((option) => option.value.toLowerCase().includes(searchTerm))
            .sort((a, b) => {
                const indexOfA = a.value.toLowerCase().indexOf(searchTerm);
                const indexOfB = b.value.toLowerCase().indexOf(searchTerm);

                // Sorteer op basis van de positie van het zoekwoord in de waarde
                return indexOfA - indexOfB;
            });
    }

    public showOptions(): void {
        this.filteredOptions = this.options;

        if (this.highlightOption >= this.filteredOptions.length) {
            this.highlightOption = 0;
        }
    }

    /** The current value of the selector */
    public set value(val: string) {
        if ((!this.options || this.options.length > 5) && this.select != undefined) {
            const eleValue: string = val == null ? '' : String(val);
            const $select: HTMLSelectElement = this.select.nativeElement;

            $select.value = null; // Make sure the shown value changes
            $select.value = eleValue;
        }

        this._value = val !== this._value ? val : null;
        this.onChange(this._value);
    }

    public writeValue(value: string): void {
        this.value = value !== this.value ? value : undefined;
    }

    public registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * When the user chooses an option in the <alt select> element
     *
     * @param     option    The    (alt select) event
     * @author    Arjan Koemans <arjan@safira.nl>
     */
    public choose(option: any, ev?: Event): void {
        const action = ev instanceof Event ? (ev as any).button : 1;

        // Already selected or right click
        if (this.value === option.id || action === 2) {
            return;
        }
        this.value = option.id;
        this.searchOption = option.value;
        this.placeholderValue = option.value;
    }

    /**
     * Has this selector been disabled?
     *
     * @author    Arjan Koemans <arjan@safira.nl>
     */
    public isDisabled(): boolean {
        return this.disabled !== false && this.disabled != null;
    }

    /**
     * Is this selector required?
     *
     * @author    Arjan Koemans <arjan@safira.nl>
     */
    public isRequired(): boolean {
        return this.required !== false && this.required != null;
    }

    checkElementVisibility() {
        const container = this.elementRef.nativeElement.querySelector('.select-options');
        const element = this.elementRef.nativeElement.querySelector('.highlight');
        if (container && element) {
            const containerRect = container.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();
            if (elementRect.top < containerRect.top || elementRect.bottom > containerRect.bottom) {
                // Element is outside the view, scroll to the top of the element
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }
}
