import { ConfigService } from './../../core/services/config.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'admin',
    templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.less'],
})
export class AdminComponent {
    constructor(public router: Router, public config: ConfigService) {} 
}
