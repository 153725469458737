import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormsModule } from '@angular/forms';
import { ForgotComponent } from './forgot/forgot.component';
import { ActivateComponent } from './activate/activate.component';
import { TranslationModule } from '../translate.module';
import { SetPasswordComponent } from './set-password/set-password.component';
import { UnblockComponent } from './unblock/unblock.component';
import { LoginWrapperComponent } from './login-wrapper/login-wrapper.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ItemModule } from '../../core/components/item/item.module';
import { FormModule } from '../../core/components/form/form.module';
import { authenticateRouting } from './authenticate.routes';
import { ValidationModule } from '../../core/directives/validation/validation.module';
import { QrcodeModule } from '../../core/components/qrcode/qrcode.module';

@NgModule({
    imports: [
        BrowserModule,
        authenticateRouting,
        FormsModule,
        FormModule,
        ValidationModule,
        TranslationModule,
        QrcodeModule,
        ItemModule,
        FormModule,
    ],
    declarations: [
        LoginComponent,
        RegisterComponent,
        ForgotPasswordComponent,
        ForgotComponent,
        ActivateComponent,
        SetPasswordComponent,
        UnblockComponent,
        LoginWrapperComponent,
        WelcomeComponent
    ],
    exports: [
        LoginComponent,
    ]
})
export class AuthenticateModule {}
