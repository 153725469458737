import { FooterModule } from './core/components/footer/footer.module';
import { MobileModule } from './core/components/mobile-bottom-bar/mobile.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AjaxService } from './core/services/ajax.service';
import { AuthenticateModule } from './modules/authenticate/authenticate.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { PrintModalComponent } from './core/components/printmodal/printmodal.component';
import { FormsModule } from '@angular/forms';
import { FormModule } from './core/components/form/form.module';
import { RedactorModule } from './core/directives/redactor/redactor.module';
import { TabModule } from './core/directives/tab/tab.module';
import { TabScrollModule } from './core/directives/tab-scroll/tab-scroll.module';
import { PopupModule } from './core/components/popup/popup.module';
import { DndModule } from './core/directives/dnd/dnd.module';
import { ValidationModule } from './core/directives/validation/validation.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { TranslationModule } from './modules/translate.module';
import { IosNotificationComponent } from './core/components/ios-notification/ios-notification.component';
import { AdminModule } from './modules/admin/admin.module';
import { AccountModule } from './modules/account/account.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { TableModule } from './core/components/table/table.module';
import { ItemModule } from './core/components/item/item.module';
import { NavigationModule } from './core/components/navigation/navigation.module';
import { PaginatorModule } from './core/components/paginator/paginator.module';
import { ToasterModule } from './core/components/toaster/toaster.module';
import { ConfiguratorModule } from './modules/configurator/configurator.module';
import { OrdersModule } from './modules/orders/orders.module';

registerLocaleData(localeNL);

// Routers
const appRoutes: Routes = [
    {
        path: '403',
        redirectTo: 'dashboard',
    },
    {
        path: '**',
        redirectTo: 'configurator',
    },
];

@NgModule({
    declarations: [AppComponent, IosNotificationComponent],
    imports: [
        AccountModule,
        BrowserModule,
        BrowserAnimationsModule,
        AdminModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        AuthenticateModule,
        FormsModule, //required for [(ngModel)]
        FormModule, // Custom Safira form module
        PaginatorModule,
        NavigationModule,
        RedactorModule,
        TabModule,
        TabScrollModule,
        PopupModule,
        DndModule,
        ValidationModule,
        DashboardModule,
        TranslationModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        TableModule,
        ItemModule,
        FooterModule,
        MobileModule,
        ToasterModule,
        ConfiguratorModule,
        OrdersModule,
    ],
    providers: [AjaxService, PrintModalComponent, { provide: LOCALE_ID, useValue: 'nl-AW' }],
    bootstrap: [AppComponent],
})
export class AppModule {}
