import { BreadcrumbService } from './../../../../core/services/breadcrumb-service.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ImportService } from 'src/app/core/services/import.service';
import { AccountService } from 'src/app/core/services/user/account.service';
import { TableService } from 'src/app/core/services/table.service';
import { UserModel } from 'src/app/core/models/user/user.model';
import { TextItem, RowItem } from 'src/app/core/models/item.model';
import { TableColumn } from 'src/app/core/models/table.model';
import { Breakpoint, ResponsiveService } from 'src/app/core/services/responsive.service';
import { AuthenticateService } from 'src/app/core/services/auth/authenticate.service';

@Component({
    selector: 'sImport',
    templateUrl: './import.component.html',
    styleUrls: ['./import.component.less'],
})
export class ImportComponent implements OnInit, OnDestroy {
    @ViewChild('input', { static: true }) public input: ElementRef;
    public userModels: UserModel[] = [];
    public importableModels: UserModel[] = [];
    public status: any[] = [];
    public sendEmail: boolean;

    button: TextItem = {
        type: 'text',
        text: 'itemUser.import_file',
        isButton: true,
        action: {
            type: 'click',
            data: this,
            click(data) {
                data.input.nativeElement.click();
            },
        },
    };

    public constructor(
        public importService: ImportService,
        public tableService: TableService,
        public accountService: AccountService,
        public erf: ElementRef,
        public toaster: ToasterService,
        private breadcrumbService: BreadcrumbService,
        private responsive: ResponsiveService,
        private authenticate: AuthenticateService
    ) {}

    async ngOnInit() {
        this.breadcrumbService.pushPage('itemUser.import');

        this.tableService.register(
            {
                paginator: true,
                breakpoint: { column: [Breakpoint.MD], row: [Breakpoint.MD] },
            },
            this.mapColumns.bind(this),
            this.mapUsersToTable.bind(this),
            await this.importService.getUsersWithBatch()
        );

        this.tableService.addSearches([
            { searchOn: 'fullName', accuracy: 80 },
            { searchOn: 'email', accuracy: 80 },
        ]);

        const filterOptions = this.tableService.getOptions('batch', 'Batch %VALUE%');
        if (filterOptions.length > 0) {
            this.tableService.addFilter([
                {
                    filterOn: 'batch',
                    selectAll: false,
                    filteredOn: filterOptions[0].value,
                    options: filterOptions,
                },
            ]);
            this.tableService.search();
        } else {
            this.tableService.addFilter([
                {
                    filterOn: 'batch',
                    selectAll: false,
                },
            ]);
            this.tableService.search();
        }
    }

    ngOnDestroy() {
        this.breadcrumbService.popPage();
        this.tableService.destroyTable();
    }

    /**
     * convert csv to an array
     *
     * @author    Mitchell Sterrenberg <mitchell@safira.nl>
     */
    public csvToArray(text, headers?, quoteChar = '"', delimiter = ',') {
        const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

        const match = (line) => {
            const matches = [...line.matchAll(regex)].map((m) => m[2]);
            matches.pop(); // cut off blank match at the end
            return matches;
        };

        const lines = text.split('\n');
        const heads = headers ?? match(lines.splice(0, 1)[0]);

        return lines.map((line) => {
            return match(line).reduce((acc, cur, i) => {
                let val = cur.length <= 0 ? null : Number(cur) || cur;
                const key = heads[i] ?? `extra_${i}`;
                return { ...acc, [key]: val };
            }, {});
        });
    }

    /**
     * add file content to the popup table
     *
     * @author    Mitchell Sterrenberg <mitchell@safira.nl>
     */
    public addFile(fileList: FileList) {
        let file: File = this.checkAllowedExtensions(fileList)[0];
        if (!file) {
            return;
        }
        let reader = new FileReader();
        reader.onloadend = async () => {
            let data = this.csvToArray(reader.result);
            let emails = data.map((record) => {
                return record.email;
            });

            //email,companyName,phoneHome
            await this.importService.checkDuplicateEmail(emails);
            data.forEach((user) => {
                let model = new UserModel(this.importService.save.bind(this.importService));
                model.email = user.email;
                model.companyName = user.companyName;
                model.phoneHome = user.phoneHome;
                model.verified = true;
                try {
                    this.importService.validate(model);
                    this.status.push('Gereed');
                    this.importableModels.push(model);
                } catch (error) {
                    this.status.push(error.message);
                }
                this.userModels.push(model);
            });
        };

        reader.readAsText(file);
        this.userModels = [];
        this.importableModels = [];
        this.status = [];
        file = null;
        this.input.nativeElement.value = null;
    }

    /**
     * save the users from the csv and add them to the database
     *
     * @author    Mitchell Sterrenberg <mitchell@safira.nl>
     */
    public async importFile() {
        const users = await this.importService.save(this.importableModels);
        this.tableService.addObjects(users);
        this.tableService.getFilters().filters = [
            {
                filteredOn: Array.isArray(users) ? users[0].batch : users.batch,
                filterOn: 'batch',
                options: this.tableService.getOptions('batch', 'Batch %VALUE%'),
                selectAll: false,
            },
        ];
        this.tableService.search();
        let data = this.importableModels.map((record) => {
            return record.email;
        });
        if (this.sendEmail === true) {
            this.importService.sendEmail(data);
        }
        document.getElementById('popup').style.display = 'none';
    }

    public change(data) {
        this.sendEmail = data;
    }

    private checkAllowedExtensions(fileList: FileList): File[] {
        let allowedFiles: File[] = [];
        for (let i = 0; i < fileList.length; i++) {
            if (fileList.item(i).name.toLowerCase().endsWith('.csv')) {
                allowedFiles.push(fileList.item(i));
            } else {
                this.toaster.error('global_error_file_type');
            }
        }
        return allowedFiles;
    }

    private mapColumns(): TableColumn[] {
        return [
            {
                item: { type: 'text', text: 'itemUser.name' },
                sortOn: 'fullName',
                width: 25,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 40,
                },
            },
            {
                item: { type: 'text', text: 'itemUser.email' },
                sortOn: 'email',
                width: 25,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 40,
                },
            },
            {
                item: { type: 'text', text: 'itemUser.verified' },
                position: 'center',
                sortOn: 'verified',
                width: 15,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
            {
                item: { type: 'text', text: 'itemUser.actions' },
                position: 'center',
                width: 25,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
        ];
    }

    /**
     * Map users to table format
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    private mapUsersToTable(user: UserModel): RowItem {
        return {
            type: 'row',
            expandable: {
                breakpoint: Breakpoint.MD,
                colspan: 2,
                gap: 10,
                closedHeight: 16.8,
                openHeight: 83.6,
            },
            items: [
                { type: 'text', fontWeight: 600, text: user.fullName, expansion: 'hidden' },
                {
                    type: 'group',
                    gap: 6,
                    expansion: 'expandable',
                    show: !this.responsive.isSize(Breakpoint.MD),
                    items: [
                        { type: 'text', fontWeight: 600, text: user.fullName },
                        {
                            type: 'bool',
                            bool: user.verified,
                            icon: 'user-check fa-sm',
                            color: 'success',
                            falseIcon: 'user-slash fa-sm',
                            falseColor: 'default',
                        },
                    ],
                },
                { type: 'text', text: user.email, show: !this.responsive.isSize(Breakpoint.MD) },
                {
                    type: 'text',
                    text: user.email,
                    hover: true,
                    show: this.responsive.isSize(Breakpoint.MD),
                    action: {
                        type: 'anchor',
                        route: 'mailto:' + user.email,
                        target: 'top',
                    },
                },
                {
                    type: 'bool',
                    bool: user.verified,
                    icon: 'user-check',
                    color: 'success',
                    falseIcon: 'user-slash',
                    falseColor: 'default',
                    position: 'center',
                    show: this.responsive.isSize(Breakpoint.MD),
                },
                {
                    type: 'group',
                    position: this.responsive.isSize(Breakpoint.MD) ? 'center' : 'left',
                    expansion: 'expandable',
                    items: [
                        {
                            type: 'text',
                            hidden: user.adminAccount,
                            show: !user.adminAccount || this.responsive.isSize(Breakpoint.MD),
                            icon: 'sign-out',
                            iconBorder: true,
                            iconHover: true,
                            title: 'itemUser.loginAs',
                            action: {
                                type: 'popup',
                                title: {
                                    type: 'group',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'itemUser.loginAs',
                                        },
                                        { type: 'text', text: user.fullName },
                                    ],
                                },
                                form: {
                                    routeTo: './',
                                    submitLabel: 'itemUser.submit_login_as',
                                    inputs: [
                                        {
                                            type: 'form',
                                            inputType: 'password',
                                            text: 'itemUser.password',
                                            name: 'itemUser.password',
                                            data: null,
                                            required: true,
                                        },
                                    ],
                                    submit: async (
                                        data: any,
                                        formData: Map<string, any>
                                    ): Promise<boolean> => {
                                        if (
                                            formData.get('password') === null ||
                                            formData.get('password').length <= 1
                                        ) {
                                            return false;
                                        }
                                        await this.authenticate.loginAs(
                                            user.userId,
                                            formData.get('password')
                                        );
                                    },
                                },
                            },
                        },
                        {
                            type: 'text',
                            icon: 'pen',
                            iconHover: true,
                            iconBorder: true,
                            action: {
                                type: 'route',
                                route: '/admin/users/' + user.userId,
                            },
                            title: 'global_edit',
                        },
                        {
                            type: 'text',
                            icon: 'trash-alt',
                            iconBorder: true,
                            hidden: user.userId === this.accountService.object.userId,
                            iconHover: true,
                            title: 'global_delete',
                            action: {
                                type: 'popup',
                                title: {
                                    type: 'group',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'itemUser.delete',
                                        },
                                        { type: 'text', text: user.fullName },
                                    ],
                                },
                                form: {
                                    routeTo: './',
                                    submitLabel: 'itemUser.submit_delete',
                                    inputs: [
                                        {
                                            type: 'form',
                                            inputType: 'checkbox',
                                            text: 'itemUser.confirm_delete',
                                            name: 'confirm',
                                            data: null,
                                            required: true,
                                        },
                                    ],
                                    submit: async (data: any, formData: Map<string, any>) => {
                                        if (formData.get('confirm')) {
                                            await this.importService.delete(user);
                                            this.tableService.setObjects(await this.importService.getAll());
                                            return true;
                                        }
                                        return false;
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        };
    }
}
